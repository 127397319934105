$Lighten:               15%;
$Darken:                15%;

.demo_bg, .demo_bd, .demo_ol, .demo_fg {
    display:block;
    width: 28px;
    height: 28px;
    font-weight: bold;
}

.demo_bd {
    border: 2px solid;
}

.demo_ol {
    outline: 2px solid
}

.demo_fg {
    width: 36px;
}

.bd-transparent {border: none;}
.bg-transparent {background-color: transparent !important;}
.bg-facebook    {background-color: #4267b2;}
.bg-twitter     {background-color: #1DA1F2;}
.bg-github      {background-color: #24292e;}
.bg-bootstrap   {background-color: #563d7c;}
.bg-gitlab      {background-color: #e65328;}
.bg-amazon      {background-color: #232f3e;}

$black:                      #000000;
$white:                      #ffffff;
$dark:                       #1d1d1d;
$light:                      #f8f8f8;
$gray:                       #bebebe;
$grayBlue:                   #607d8b;
$grayWhite:                  #f5f5f5;
$grayMouse:                  #455a64;

$colorList: (
    'white':                 #FFFFFF,
    'black':                 #000000,

    'grey':                  #666666,
    'grey-med':              #495e66,
    'grey-dark':             #72665e,

    'red':                   #FF6666,
    'red-med':               #CC3333,
    'red-dark':              #663333,

    'orange':                #FF9966,
    'orange-med':            #CC6633,
    'orange-dark':           #FF9966,

    'green':                #66FF66,
    'green-med':            #33CC33,
    'green-dark':           #009900,

    'yellow':                #FFFF66,
    'yellow-med':            #CCCC33,
    'yellow-dark':           #999900,

    'sky':                   #66FFFF,
    'sky-med':               #33CCCC,
    'sky-dark':              #009999,

    'blue':                   #6666FF,
    'blue-med':               #3333CC,
    'blue-dark':              #000099,

    'purple':                 #9933FF,
    'purple-med':             #3300CC,
    'purple-dark':            #330033,

    'pink':                   #FF66FF,
    'pink-med':               #CC33CC,
    'pink-dark':              #990099,

    'ep-blue':                #366CFF,
    'ep-red':                 #E7444B,
    'ep-jaune':               #F0C300,
    'ep-bleuvert':            #048B9A

);

@mixin create_css_class($i, $name, $ColorBase) {
    $Light:              lighten($ColorBase, $Lighten);
    $Dark:               darken($ColorBase, $Darken);

    .fg-l#{$i}-#{$name}-light                  {color: $Light !important;}
    .fg-l#{$i}-#{$name}-light-hover:hover      {color: $Light !important;}
    .fg-l#{$i}-#{$name}                        {color: $ColorBase !important;}
    .fg-l#{$i}-#{$name}-hover:hover            {color: $ColorBase !important;}
    .fg-l#{$i}-#{$name}-dark                   {color: $Dark !important;}
    .fg-l#{$i}-#{$name}-dark-hover:hover       {color: $Dark !important;}

    .bg-l#{$i}-#{$name}-light                  {background-color: $Light !important;}
    .bg-l#{$i}-#{$name}-light-hover:hover      {background-color: $Light !important;}
    .bg-l#{$i}-#{$name}                        {background-color: $ColorBase !important;}
    .bg-l#{$i}-#{$name}-hover:hover            {background-color: $ColorBase !important;}
    .bg-l#{$i}-#{$name}-dark                   {background-color: $Dark !important;}
    .bg-l#{$i}-#{$name}-dark-hover:hover       {background-color: $Dark !important;}

    .bd-l#{$i}-#{$name}-light                  {border-color: $Light !important;}
    .bd-l#{$i}-#{$name}-light-hover:hover      {border-color: $Light !important;}
    .bd-l#{$i}-#{$name}                        {border-color: $ColorBase !important;}
    .bd-l#{$i}-#{$name}-hover:hover            {border-color: $ColorBase !important;}
    .bd-l#{$i}-#{$name}-dark                   {border-color: $Dark !important;}
    .bd-l#{$i}-#{$name}-dark-hover:hover       {border-color: $Dark !important;}

    .ol-l#{$i}-#{$name}-light                  {outline-color: $Light !important;}
    .ol-l#{$i}-#{$name}-light-hover:hover      {outline-color: $Light !important;}
    .ol-l#{$i}-#{$name}                        {outline-color: $ColorBase !important;}
    .ol-l#{$i}-#{$name}-hover:hover            {outline-color: $ColorBase !important;}
    .ol-l#{$i}-#{$name}-dark                   {outline-color: $Dark !important;}
    .ol-l#{$i}-#{$name}-dark-hover:hover       {outline-color: $Dark !important;}
}

@each $name, $color in $colorList {
    @for $i from 1 through 3 {
        $level:              abs($i - 3);
        $ColorBase:          lighten($color, $level*20%);
        @include create_css_class($i, $name, $ColorBase);
    }
    @for $i from 4 through 5 {
        $level:              abs($i - 3);
        $ColorBase:          darken($color, $level*20%);
        @include create_css_class($i, $name, $ColorBase);
    }
}

@for $i from 0 through 100 {
    .bga-black-#{$i} {
        background-color: rgba(0, 0, 0, calc($i / 100));
    }
}